@media screen and (max-width: 768px) {
  table thead {
    display: none; /* Only hide the header on small screens */
  }

  table tbody tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }

  table tbody tr td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  table tbody tr td:last-child {
    border-bottom: none;
  }
}
