/* App.module.css */

.container {
  height: 100vh;
  width: 100vw; /* Full viewport width */
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  background-color: white; /* Ensure the container background is white */
}

.image {
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-size: contain; /* Ensure the full image is visible without being cut */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  scroll-snap-align: start;
}

.button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .image {
    background-size: contain; /* Ensure full image is visible */
  }
}
