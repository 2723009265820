main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

/* .carousel-caption {
  position: relative;
  height: 300px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.carousel img {
  position: absolute;
  height: 300px;
  padding: 30px;
  left: 40%;
  margin: auto;
  border-radius: 50%;
} */
/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 768px) {
  table thead {
    display: none; /* Only hide the header on small screens */
  }

  table tbody tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }

  table tbody tr td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  table tbody tr td:last-child {
    border-bottom: none;
  }
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.image-container {
  height: 100vh;
  width: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.image {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  scroll-snap-align: start;
}

.flip-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
