/* Define complex animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated {
  animation: pulse 0.5s ease-in-out, rotate 1s ease-in-out;
}

.user-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  transition: transform 0.2s;
}

.user-icon-container:hover {
  transform: scale(1.05);
}

.user-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.score {
  font-size: 16px;
  font-weight: bold;
}

/* TableGame.css */
